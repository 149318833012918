// i18next-extract-mark-ns-start products-ignite-cs

import { IGNITE_NAV } from '@utils/constants';
import { graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React from 'react';

import Aside from '@components/Aside';
import AssetList from '@components/AssetList';
import Banner from '@components/Banner';
import Breadcrumbs, { Breadcrumb } from '@components/Breadcrumbs';
import PageSection from '@components/PageSection';
import Page from '@components/layout/Page';
import SEO from '@components/layout/SEO';
import { Link, Trans, useTranslation } from 'gatsby-plugin-react-i18next';

const ProductIgnitePage = ({
	pageContext: {
		breadcrumb: { crumbs },
	},
	data
}: {
	pageContext: {
		breadcrumb: { crumbs: Breadcrumb[] };
	};
	data: any;
}) => {
	const { t } = useTranslation(); const imageVW = getImage(data.imageVW.childImageSharp.gatsbyImageData);
	const imageTLK = getImage(data.imageTLK.childImageSharp.gatsbyImageData);
	const imageCover = getImage(
		data.imageCover.childImageSharp.gatsbyImageData
	);

	return (
		<Page>
			<SEO
				title={t("seo_title")}
				description={t("seo_description")}
			/>

			<Banner
				title={t("title")}
				image={{
					src: data.banner.childImageSharp.gatsbyImageData,
					alt: '',
				}}
				size="narrow"
			/>

			<Breadcrumbs crumbs={crumbs} />

			<PageSection
				className="section__content section__content--dual"
				layout="none">
				<div className="col xl7 m12 m-spacer page__content page__content--dual">
					<Trans i18nKey="content_1">
						<h2>
							Optimizing drive-cycle waste-heat recovery systems using
							the Functional Mock-up Interface
						</h2>
						<p>
							Realis Simulation worked in collaboration with
							Volkswagen AG Research (VWAG) to develop new methods for
							design and optimization of waste-heat recovery systems
							in future vehicles.
						</p>
					</Trans>
					<p>
						{imageVW && (
							<GatsbyImage
								image={imageVW}
								alt="VW"
								className="icon-image"
							/>
						)}
					</p>
					<Trans i18nKey="content_2">
						<p>
							For vehicle manufacturers, there is an opportunity to
							optimize waste-heat recovery systems for the benefit of
							recovering exhaust gas and converting it into usable
							mechanical and electrical energy. This application
							represents an emerging automotive technology for
							improving fuel efficiency and reducing CO2 emissions.
							Successful simulation of waste heat recovery systems
							requires modelling and capturing the complex
							interactions between multiple vehicle sub-systems, and
							as a result often requires the use of multiple CAE
							software tools.&nbsp; This approach can be cumbersome,
							costly, inefficient, and error prone.&nbsp; Recognizing
							the challenge, Volkswagen utilized Realis Simulation
							formerly Ricardo Software’s model based design CAE
							software <a href="/Products/IGNITE">IGNITE</a> to
							develop new methods for the optimization of waste heat
							recovery systems in future vehicles.
						</p>
						<p>
							In order to achieve the level of fidelity and sub-system
							dynamics required for accurate waste heat recovery
							system optimization, the functional mock-up interface
							was used to combine three domain-specific models in
							IGNITE resulting in a single integrated model.
						</p>
						<p>
							First, IGNITE was used to construct a full system-level
							vehicle model needed to confirm boundary conditions and
							overall vehicle performance. This initial concept level
							model included a map-based engine model, physics-based
							plant models of the transmission and driveline, and a
							longitudinal dynamics capable vehicle. It also included
							drive-cycle specific vehicle controllers for driver
							behavior, transmission, shift and engine strategies.
						</p>
						<p>
							Using the industry standard Functional Mock-up Interface
							(FMI), engineers rapidly added new and more
							comprehensive sub-system models including a transient 1D
							gas dynamics engine; exported from Realis Simulation
							formerly Ricardo Software’s{' '}
							<a href="/Products/WAVE-RT">WAVE-RT</a> toolset using
							the FMI standard.
						</p>
					</Trans>
					<p>
						{imageTLK && (
							<GatsbyImage
								image={imageTLK}
								alt="TLK"
								className="icon-image"
							/>
						)}
					</p>
					<Trans i18nKey="content_3">
						<p>
							With a transient engine integrated directly within the
							vehicle model, the next step was to add a detailed waste
							heat recovery sub-system model. Using TLK-Thermo’s
							TIL-Suite library, Volkswagen built a physics-based
							model of an organic Rankine system, which included
							physical models for the pump, evaporator, and expander
							and condenser operation. Similar to WAVE-RT, the TLK
							model was imported, using FMI standards, directly into
							the IGNITE environment and combined with the engine and
							vehicle models.
						</p>
						<p>
							By combining flexible modelling fidelity with the
							multi-domain integration capabilities of the Functional
							Mock-Up Interface, IGNITE provided Volkswagen engineers
							with a flexible and efficient approach to advanced
							systems simulation and optimization.
						</p>
						<h2>Download a copy of the case study!</h2>
					</Trans>
					<p>
						<a
							href="/documents/ignite-case-study-web.pdf"
							target="_blank">
							{imageCover && (
								<GatsbyImage
									image={imageCover}
									alt="IGNITE case study cover image."
								/>
							)}
						</a>
					</p>
				</div>

				<Aside>
					<Aside.Widget
						title={t("_Applications")}
						className="widget__sibling__pages">
						<AssetList>
							{IGNITE_NAV.map((item, i) => (
								<Link
									key={i}
									to={item.value}
									className="assets__link"
									activeClassName="active">
									{t(item.labelkey) /* i18next-extract-disable-line */}
								</Link>
							))}
						</AssetList>
					</Aside.Widget>
				</Aside>
			</PageSection>
		</Page>
	);
};

export default ProductIgnitePage;

export const pageQuery = graphql`
query ($language: String!) {
	locales: allLocale(
		filter: { ns: { in: ["products-ignite-cs", "_common"] }, language: { eq: $language } }
	) {
		edges {
			node {
				ns
				data
				language
			}
		}
	}
	banner: file(relativePath: { eq: "content/products/ignite/ignite-hero_1152x654.png" }) {
		...imageBreaker
	}
	imageVW: file(relativePath: { eq: "content/vw.png" }) {
		...imageStandard
	}
	imageTLK: file(relativePath: { eq: "content/tlk_logo.png" }) {
		...imageStandard
	}
	imageCover: file(
		relativePath: { eq: "content/products/ignite/ignite-case-study-cover-2.png" }
	) {
		...imageStandard
	}
}
`;
